import { ReactElement } from "react";

type StepbyStepInstallProps = {
  title: string;
  description: ReactElement<string>;
  photo: string;
};

const StepbyStepInstall = ({
  title,
  description,
  photo,
}: StepbyStepInstallProps) => {
  return (
    <div className="flex flex-col items-center w-full lg:w-2/4 md:w-2/4">
      <h2 className="text-2xl font-bold underline decoration-purple-400">
        {title}
      </h2>
      <p className="text-lg lg:text-xl md:text-xl w-full lg:w-3/4 md:3/4 my-3 p-2">
        {description}
      </p>

      <div>
        <img
          className="contains-object"
          src={photo}
          alt="Capture d'écran de l'application"
          width={250}
          height={300}
        />
      </div>
    </div>
  );
};
export default StepbyStepInstall;
