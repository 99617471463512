const JanazaReward = () => {
  return (
    <div className="mt-5">
      <h1 className="flex justify-center text-xl lg:text-2xl md:text-2xl font-bold underline">
        Les avantages de l'assistance à une janazah
      </h1>
      <article className="w-2/2 lg:w-1/2 md:1/2 mx-auto p-2 lg:p-0 md:p-0">
        <p className="my-4 text-lg">
          Les avantages de l'assistance à une janazah (funérailles) dans l'Islam
          sont nombreux.
          <br /> Tout d'abord, la janazah est considérée comme une sunnah du
          prophète Mohammed s.a.w et un droit pour le défunt d'être assisté par
          des musulmans lors de ses funérailles.
          <br /> Assister à une janazah est également d'une grande valeur
          spirituelle car cela nous rappelle que nous retournerons tous un jour
          auprès d'Allah et nous aide à mieux apprécier la vie. <br /> En outre,
          cela témoigne de la solidarité de la communauté avec la famille du
          défunt.
        </p>
        <p className="my-4 text-lg">
          La prière funèbre est prescrite aussi bien aux femmes qu'aux hommes,
          <br />
          D'après Abou Houreira (qu'Allah l'agrée), le Prophète (que la prière
          d'Allah et Son salut soient sur lui) a dit: « Celui qui assiste à des
          funérailles jusqu'à ce que l'on prie sur le mort a un qirat et celui
          qui y assiste jusqu'à l'enterrement a deux qirat (*) ». <br />{" "}
          Quelqu'un a dit: Qu'est ce que deux qirat ? Le Prophète (que la prière
          d'Allah et Son salut soient sur lui) a dit: « Comme deux immenses
          montagnes ». (Rapporté par Mouslim dans son Sahih n°945)
        </p>
        <p className="my-4 text-lg">
          <u className="font-bold mb-2">A Savoir :</u>
          <br /> S'occuper des Obsèques du défunt musulman, le laver et
          accomplir la prière sur lui est une obligation communautaire ou «
          exonératoire » (فرض كفاية -fard kifayah).
          <br /> Si un ou des membres de la communauté l'accomplit, il aura seul
          sa récompense de la part d'Allah mais toute la communauté se trouvera
          exonérée et ne sera pas châtiée par Allah.
          <br /> En revanche, si personne ne se charge de ce devoir, toute la
          communauté sera responsable et s’exposera à la sanction d’Allah.
        </p>
      </article>
      <div className="w-2/2 lg:w-1/2 md:1/2 mx-auto p-2 lg:p-0 md:p-0">
        <h5 className="text-xl font-bold">Liens:</h5>
        <ul className="list-inside list-disc">
          <li>
            <a
              className="underline text-blue-700 hover:text-blue-900 visited:text-purple-600"
              target="_blank"
              rel="noreferrer"
              href="http://www.3ilmchar3i.net/article-comment-faire-la-priere-mortuaire-en-image-96804946.html"
            >
              Comment faire la prière mortuaire ?
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default JanazaReward;
