import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SetStateAction } from "react";

type SearchCityProps = {
  searchTerm: string;
  setSearchTerm: React.Dispatch<SetStateAction<string>>;
};

const SearchCity = ({ searchTerm, setSearchTerm }: SearchCityProps) => {
  const success = "";
  if (success) {
    console.log("defined");
  }
  return (
    <div
      id="search"
      className="flex justify-center w-full relative mx-auto lg:w-2/6 md:w-w-2/6 sm:w-4/5"
    >
      <div className="absolute inset-y-0 right-0 flex items-center px-2 pt-3">
        <FontAwesomeIcon icon={faSearch} className="h-6 w-6 text-gray" />
      </div>

      <input
        className="w-full mt-7 text-gray-700 p-4 mb-4 rounded-lg border-2 border-gray shadow-md   focus:border-purple-300 focus:outline-none"
        type="text"
        placeholder="Entrez une ville ex: Paris.."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          e.preventDefault();
        }}
      />
    </div>
  );
};

export default SearchCity;
