import {
  faDoorClosed,
  faHospital,
  faMosque,
  faRoad,
  faTruckMedical,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
type AddressIconProps = {
  types: string[];
};
const AddressIcon = ({ types }: AddressIconProps) => {
  if (types.includes("funeral_home") || types.includes("general_contractor")) {
    return (
      <FontAwesomeIcon
        className="h-4 w-4 lg:h-5 lg:w-5 md:h-5 md:w-5  text-purple-700 mr-1"
        icon={faHospital}
      />
    );
  }
  if (types.includes("hospital") || types.includes("health")) {
    return (
      <FontAwesomeIcon
        className="h-4 w-4 lg:h-5 lg:w-5 md:h-5 md:w-5  text-purple-700 mr-1"
        icon={faTruckMedical}
      />
    );
  }
  if (types.includes("cemetery")) {
    return (
      <FontAwesomeIcon
        className="h-4 w-4 lg:h-5 lg:w-5 md:h-5 md:w-5  text-purple-700 mr-1"
        icon={faDoorClosed}
      />
    );
  }
  if (
    types.includes("mosque") ||
    types.includes("place_of_worship") ||
    types.includes("masjid")
  ) {
    return (
      <FontAwesomeIcon
        className="h-4 w-4 lg:h-5 lg:w-5 md:h-5 md:w-5  text-purple-700 mr-1"
        icon={faMosque}
      />
    );
  }
  return (
    <FontAwesomeIcon
      className="h-4 w-4 lg:h-5 lg:w-5 md:h-5 md:w-5  text-purple-700 mr-1"
      icon={faRoad}
    />
  );
};
export default AddressIcon;
