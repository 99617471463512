import { DateOptionProps } from "../types/index.types";

export class DateFormatUtils {
  static dateToFrenchFormat(date: Date) {
    const dateNew = new Date(date);
    const options: DateOptionProps = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return dateNew.toLocaleDateString("fr-FR", options);
  }
}
