import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateFormatUtils } from "../utils/dateFormatUtils";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase";

type TableAdminProps = {
  post: {
    id: any;
    dateJanaza: any;
    username: string;
    phoneNumber: number;
    timeStamp: any;
  };
  // handleDelete: (e: React.FormEvent) => Promise<void>;
};
const TableAdmin = ({ post }: TableAdminProps) => {
  const [janazaBg, setJanazaBg] = useState("");
  const dateJanaza = post?.dateJanaza;
  //janaza date
  const formattedDateJanaza = DateFormatUtils.dateToFrenchFormat(dateJanaza);
  useEffect(() => {
    //date of today
    const now = new Date();

    //date of janaza
    const janazaDate = new Date(dateJanaza);

    //Check if the date of janaza is passed make bg gray
    if (janazaDate.getDate() === now.getDate()) {
      setJanazaBg("bg-white");
    } else if (janazaDate < now) {
      setJanazaBg("bg-gray");
    } else {
      setJanazaBg("bg-white");
    }
  }, [dateJanaza]);
  //date of creation
  const dateOfCreation = DateFormatUtils.dateToFrenchFormat(
    post?.timeStamp?.toDate().toString()
  );

  const handleDelete = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await deleteDoc(doc(db, "all-janaza", post?.id));
      // window.location.replace("/");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <table className="table-fixed w-full border-collapse border border-black">
      <thead className={`text-xs font-semibold uppercase ${janazaBg}`}>
        <tr>
          <th className="p-2">
            <div className="font-bold uppercase text-left">Id</div>
          </th>
          <th className="p-2">
            <div className="font-bold uppercase text-left">Username</div>
          </th>
          <th className="p-2">
            <div className="font-bold uppercase text-left">
              Date de la janaza
            </div>
          </th>

          <th className="p-2">
            <div className="font-bold uppercase text-left">Ajouter le</div>
          </th>
          <th className="p-2">
            <div className="font-bold text-center">Action</div>
          </th>
        </tr>
      </thead>

      <tbody className="text-sm divide-y divide-gray-100">
        <tr className={`p-2 ${janazaBg}`}>
          <td className="p-2">
            <div className="font-normal text-gray-800 break-words">
              {post?.id}
            </div>
          </td>
          <td className="p-2">
            <div className="font-normal text-gray-800">{post?.username}</div>
          </td>
          <td className="p-2">
            <div className="text-left">{formattedDateJanaza}</div>
          </td>

          <td className="p-2">
            <div className="text-left font-normal text-green-500">
              {dateOfCreation}
            </div>
          </td>
          <td className="p-2">
            <div className="flex justify-center">
              <button onClick={handleDelete}>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="h-6 w-6 mx-2 text-red hover:opacity-75"
                />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default TableAdmin;
