import { useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { DocumentData } from "firebase/firestore";
import { Dispatch, SetStateAction } from "react";
import SearchAddressInput from "./SearchAddressInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointLeft } from "@fortawesome/free-solid-svg-icons";
import Spinner from "./Spinner";
import AlertMessage from "./AlertMessage";

export type FormJanazaProp = {
  handleChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => void;
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  setShowUpdateForm?: Dispatch<SetStateAction<boolean>>;
  showUpdateForm?: boolean;
  post?: DocumentData | undefined;
  setAddressInput?: Dispatch<SetStateAction<string>>;
  addressInput?: any;
  success?: boolean;
  error?: string;
};
const FormJanaza = ({
  handleChange,
  handleSubmit,
  post,
  showUpdateForm,
  setShowUpdateForm,
  setAddressInput,
  error,
  addressInput,
  success,
}: FormJanazaProp) => {
  const [isNameDeceased, setIsNameDeceased] = useState(false);
  const [isAtTheMasjid, setIsAtTheMasjid] = useState(false);
  const { user } = UserAuth();

  const handleCancel = (e: React.FormEvent) => {
    e.preventDefault();
    if (setShowUpdateForm) {
      setShowUpdateForm(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'
    >
      <h1 className='text-center'>
        <span className='font-bold'>‎كُلُّ نَفْسٍ ذَائِقَةُ الْمَوْتِ </span>
        <br /> «Chaque âme goûtera la mort» (S3, V185)
      </h1>
      {isNameDeceased ? (
        <div className=' my-5'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>
            Personne décédé
          </label>
          <input
            className='shadow rounded w-full lg:w-3/4 md:w-3/4 py-2 px-3 text-gray-700'
            id='deceased'
            name='deceased'
            type='text'
            placeholder='Personne décédé'
            onChange={handleChange}
            value={post?.deceased}
          />
        </div>
      ) : (
        <div className='flex justify-center items-center my-5'>
          <button
            className='bg-blue-700 hover:bg-opacity-80 rounded-md p-2 lg:p-3 md:p-3 text-white font-medium shadow-lg '
            onClick={() => setIsNameDeceased(!isNameDeceased)}
          >
            Si vous connaissez le nom de la personne décedée cliquer sur le
            bouton{" "}
            <FontAwesomeIcon className='h-5 w-5' icon={faHandPointLeft} />
          </button>
        </div>
      )}

      <SearchAddressInput
        setAddressInput={setAddressInput}
        setIsAtTheMasjid={setIsAtTheMasjid}
      />
      {error && <AlertMessage message={error} color='red' text='red' />}

      <div className=' my-5'>
        <label className=' block text-gray-700 text-sm font-bold mb-2'>
          Heure de la prière mortuaire <span className='text-red-700'>*</span>
        </label>
        <div
          className={`flex ${
            isAtTheMasjid ? "flex-wrap justify-start" : "justify-start"
          } items-center`}
        >
          <input
            className='shadow rounded w-full lg:w-3/4 md:w-3/4 p-2 text-gray-700 m-1 mb-2'
            id='time'
            name='time'
            type='time'
            placeholder='Heure de la prière mortuaire'
            onChange={handleChange}
            value={post?.time}
          />
          {isAtTheMasjid && (
            <>
              <label className=' block text-gray-700 text-sm font-bold my-2'>
                A quelle prière la janaza est-elle accomplie ? *
              </label>
              <select
                id='prayerName'
                name='prayerName'
                onChange={handleChange}
                value={post?.prayerName}
                className=' bg-white p-3 shadow rounded-md w-full lg:w-3/4 md:w-3/4 m-1'
                required
              >
                <option hidden>Quelle prière ? *</option>
                <option value='Fajr'>Fajr</option>
                <option value='Dohr'>Dohr</option>
                <option value='Asr'>Asr</option>
                <option value='Maghreb'>Maghreb</option>
                <option value='Isha'>Isha</option>
                <option value='Jummua'>Jumua</option>
              </select>
              <select
                id='prayerPosition'
                name='prayerPosition'
                onChange={handleChange}
                className='bg-white p-3 shadow rounded-md w-full lg:w-3/4 md:w-3/4 m-1'
                value={post?.prayerPosition}
                required
              >
                <option hidden>Quand ? *</option>
                <option value='Après'>Après</option>
                <option value='Avant '>Avant</option>
              </select>
            </>
          )}
        </div>
      </div>
      <div className=' my-5'>
        <label className='block text-gray-700 text-sm font-bold mb-2'>
          Date de la janaza <span className='text-red-700'>*</span>
        </label>
        <input
          className='shadow rounded w-full lg:w-3/4 md:w-3/4 py-2 px-3 text-gray-700'
          id='dateJanaza'
          name='dateJanaza'
          type='date'
          placeholder='Date de la janaza'
          onChange={handleChange}
          value={post?.dateJanaza}
          required
        />
      </div>
      {/* if the user authenticate with gmail will ask for phone number*/}
      {!user?.phoneNumber && (
        <div className=' my-5'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>
            Personne a contacté pour plus de précision
          </label>
          <input
            className='shadow rounded w-full lg:w-3/4 md:w-3/4 py-2 px-3 text-gray-700'
            id='phoneNumber'
            name='phoneNumber'
            type='tel'
            placeholder='Numéro de téléphone'
            onChange={handleChange}
            value={post?.phoneNumber}
            required
          />
        </div>
      )}
      {/* if the user authenticate with phone number will ask for username*/}
      {!user?.displayName && (
        <div className=' my-5'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>
            Username <span className='text-red-700'>*</span>
          </label>
          <input
            className='shadow rounded w-full lg:w-3/4 md:w-3/4 py-2 px-3 text-gray-700'
            id='username'
            name='username'
            type='text'
            placeholder='Ajouter un username'
            onChange={handleChange}
            value={post?.username}
            required
          />
        </div>
      )}

      <div className=' my-5'>
        <label className='block text-gray-700 text-sm font-bold mb-2'>
          Détail sur la janaza
        </label>
        <textarea
          className='shadow rounded w-full lg:w-3/4 md:w-3/4 py-2 px-3 text-gray-700'
          id='detail'
          name='detail'
          placeholder="ex: le lieu de l'enterrement, etc..."
          onChange={handleChange}
          value={post?.detail}
        />
      </div>
      {!showUpdateForm ? (
        <button
          className='group relative w-full flex justify-center p-3 mb-5 text-xl font-medium rounded-md text-white bg-purple-400'
          type='submit'
          disabled={success}
        >
          {success ? (
            <Spinner height={5} width={5} justify='center' color='white' />
          ) : (
            <span className='text-lg'>Ajouter</span>
          )}
        </button>
      ) : (
        <>
          <div className='flex justify-center w-full'>
            <button
              type='submit'
              className='bg-blue-700 hover:bg-opacity-80 rounded-md p-2 lg:p-3 md:p-3 text-white mx-2 font-medium shadow-lg w-1/2'
            >
              Update
            </button>
            <button
              onClick={handleCancel}
              className='bg-purple-400 hover:bg-opacity-80 rounded-md p-2 lg:p-3 md:p-3 text-white mx-2 font-medium shadow-lg w-1/2'
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default FormJanaza;
