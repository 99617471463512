import React, { useEffect, useState } from "react";
import { GoogleLoginButton } from "react-social-login-buttons";
import { UserAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import AlertMessage from "../components/AlertMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import Logo from "./../img/janazafinder-removebg.png";

const Login = () => {
  const { googleSignIn, user } = UserAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    try {
      await googleSignIn();
    } catch (err: any) {
      if (err?.code === "auth/wrong-password") {
        setErrorMessage("Mot de passe incorrect");
      } else if (err?.code === "auth/user-not-found") {
        setErrorMessage("Utilisateur introuvable");
      } else if (err?.code === "auth/invalid-email") {
        setErrorMessage("Email non valide");
      } else if (err?.code === "auth/too-many-requests") {
        setErrorMessage("Veuillez réeassayer plus tard");
      } else if (err?.code === "auth/popup-closed-by-user") {
        setErrorMessage(
          "La connexion a échoué. Veuillez réessayer et laisser la fenêtre d'authentification ouverte"
        );
      }
    }
  };
  // const handleMicrosoftLogin = async () => {
  //   try {
  //     await microsoftSignIn();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (user != null) {
      navigate("/add");
    }
  }, [user, navigate]);

  return (
    <div className="h-auto lg:h-screen md:h-screen flex">
      {/* Left section */}
      <div
        className="hidden lg:flex w-full lg:w-1/2
          justify-around items-center bg-purple-300 "
      >
        <div className="w-full mx-auto px-20 flex-col items-center space-y-4">
          <h1 className="text-white font-bold text-6xl font-sans">
            Janaza Finder
          </h1>
          <p className="text-white text-2xl">Montagnes de récompense</p>
          <div className="flex justify-center lg:justify-start mt-6">
            <Link
              to="/janaza-info"
              className="hover:bg-indigo-700 hover:text-purple-400 hover:-translate-y-1 transition-all duration-500 bg-white text-indigo-800 mt-4 px-4 py-2 rounded-2xl font-bold mb-2 text-xl"
            >
              Plus d'informations
            </Link>
          </div>
        </div>
      </div>
      {/* Right section */}
      <div className="flex w-full lg:w-1/2 justify-center items-center space-y-8">
        <div className="w-full px-8 md:px-32 lg:px-24">
          <form className="shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <img
              src={Logo}
              alt="logo janaza"
              className="mx-auto object-contains h-40"
            />
            <p className="flex justify-center text-2xl">Se Connecter</p>
            <p className="flex justify-center text-lg text-purple-400">
              Créer une alerte janaza
            </p>

            <div className="flex justify-center my-5">
              <GoogleLoginButton onClick={handleGoogleLogin} />
            </div>
            <Link to="/phonelogin">
              <div className="w-full flex justify-center my-5">
                <button className="flex justify-start items-center w-full p-3 text-xl text-white bg-gradient-to-r from-purple-400 to-purple-800 rounded-md shadow-md">
                  <FontAwesomeIcon
                    icon={faPhone}
                    className="h-6 w-6 text-white mr-2"
                  />{" "}
                  Log in with Phone
                </button>
              </div>
            </Link>
            {/* <div className="flex justify-center my-5">
                <MicrosoftLoginButton onClick={handleMicrosoftLogin} />
              </div> */}
            {errorMessage && (
              <AlertMessage
                message={errorMessage}
                color="red"
                text="red"
                bg="white"
              />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
