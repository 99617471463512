import { useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";

const GreetingMessage = () => {
  const { user } = UserAuth();
  const [greeting, setGreeting] = useState(false);

  useEffect(() => {
    // Check if the greeting message has been shown before
    const hasSeenGreeting = localStorage.getItem("hasSeenGreeting");

    if (hasSeenGreeting === "false") {
      console.log("Greeting message has not been shown before");
      setGreeting(true);

      const timer = setTimeout(() => {
        setGreeting(false);
        // Update local storage to indicate that the greeting message has been shown
        localStorage.setItem("hasSeenGreeting", "true");
      }, 2000);

      // Clean up the timer when the component is unmounted
      return () => clearTimeout(timer);
    }
  }, [user]);

  const msgGreeting =
    "Autorisez les notifications pour être informé instantanément lorsqu'une janaza est créé.";
  const closeAlert = () => {
    setGreeting(false);
    localStorage.setItem("hasSeenGreeting", "true");
  };

  return (
    <>
      {greeting && (
        <div className='flex justify-center mt-4'>
          <div
            className='flex justify-center flex-wrap w-full lg:w-1/2 md:w-1/2 sm:w-1/2 text-center bg-purple-100 border border-purple-500 text-black px-4 py-3 rounded relative'
            role='alert'
          >
            <strong className='font-bold'>🔔{msgGreeting}🔔 </strong>
            {/* <span className='block sm:inline'>
              {user.displayName?.charAt(0).toUpperCase() +
                user.displayName?.slice(1)}
            </span> */}
            <button
              type='button'
              onClick={closeAlert}
              className='absolute top-0 bottom-0 right-0 px-4 py-3'
            >
              <svg
                className='fill-current h-6 w-6 text-purple-500'
                role='button'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
              >
                <title>Close</title>
                <path d='M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z' />
              </svg>
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default GreetingMessage;
