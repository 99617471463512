import React, { ReactNode, useState } from 'react';

interface TooltipProps {
  children: ReactNode;
  tooltipMessage: string;
}

const Tooltip = ({ children, tooltipMessage }: TooltipProps) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      {children}
      {isTooltipVisible && (
        <div className="absolute z-10 p-2 text-sm lg:text-md md:text-md w-14 lg:w-24 md:w-24 text-blue-700 bg-light-gray rounded-md shadow-lg">
          {tooltipMessage}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
