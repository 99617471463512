import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import AlertMessage from "../components/AlertMessage";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
const Contact = () => {
  const navigate = useNavigate();
  const ref = useRef<HTMLFormElement>(null);
  const [success, setSuccess] = useState<boolean | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (ref.current) {
      emailjs
        .sendForm(
          process.env.REACT_APP_EMAIL_JS_SERVICE_ID!,
          process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID!,
          ref.current,
          process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
        )
        .then(
          (result) => {
            setSuccess(true);

            setTimeout(() => {
              navigate("/");
            }, 2000);
          },
          (error) => {
            console.log(error.text);
            setSuccess(false);
          }
        );
    }
  };
  return (
    <div className="mt-5">
      <h1 className="flex justify-center text-2xl font-bold underline ">
        Contactez-nous
      </h1>
      <h3 className="flex justify-center italic my-3 p-2">
        L’équipe Janaza Finder est à votre écoute, vous pouvez nous contacter
        pour toutes questions ou suggestions.
      </h3>

      <form
        ref={ref}
        onSubmit={handleSubmit}
        className="w-full max-w-lg mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 my-4"
      >
        <div className="flex flex-wrap center -mx-3 mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase text-xs font-bold mb-2"
              htmlFor="name"
            >
              Name*
            </label>

            <input
              className="shadow-md rounded w-full py-2 px-3 text-gray-700"
              id="name"
              type="text"
              placeholder="Entrez votre nom"
              name="name"
              required
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-xs font-bold mb-2"
              htmlFor="email"
            >
              E-mail*
            </label>
            <input
              className="shadow-md rounded w-full py-2 px-3 text-gray-700"
              id="email"
              type="email"
              placeholder="Entrez votre email"
              name="email"
              required
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-xs font-bold mb-2"
              htmlFor="message"
            >
              Message*
            </label>
            <textarea
              className="no-resize shadow-md rounded w-full py-2 px-3 text-gray-700r"
              id="message"
              placeholder="Ecrivez votre message"
              name="message"
              rows={10}
              required
            ></textarea>
          </div>
        </div>
        <div className="w-full">
          <div className="w-full">
            <button
              className="w-full bg-blue-700 hover:bg-opacity-80 rounded-md p-2 text-white mx-2 font-medium shadow-lg"
              type="submit"
            >
              {success ? (
                <Spinner height={5} width={5} justify="center" color="white" />
              ) : (
                <span className="text-lg">Envoyer</span>
              )}
            </button>
          </div>

          <div className="md:w-2/3"></div>
        </div>
        {success && (
          <div className="mt-2">
            <AlertMessage
              text="green-600"
              bg="white"
              color="green-apple"
              message="Votre message a été envoyé. Nous reviendrons vers vous dans les plus brefs délais"
            />
          </div>
        )}
      </form>
    </div>
  );
};

export default Contact;
