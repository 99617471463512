import { useState } from "react";
import ToolTip from "./ToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faMap } from "@fortawesome/free-solid-svg-icons";

type DisplayJanazaButtonProps = {
  handleNextDateClick: () => void;
  handleAllJanazaClick: () => void;
  handleTodayJanazaClick: () => void;
  handleShowMap: () => void;
  showMap: boolean
};

const DisplayJanazaButton = ({
  handleAllJanazaClick,
  handleTodayJanazaClick,
  handleNextDateClick,
  handleShowMap,
  showMap
}: DisplayJanazaButtonProps) => {
  const [selectedButton, setSelectedButton] = useState("allJanazas");
  const msgToolTip: string = 'Afficher la carte';
  const msgToolTip2: string = 'Fermer la carte'

  const handleClick = (buttonName: string, clickHandler: () => void) => {
    setSelectedButton(buttonName);
    clickHandler();
  };
  return (
    <div className="flex justify-center align-center">
      <button
        className={`bg-blue-700 hover:bg-opacity-80 ${
          selectedButton === "allJanazas"
            ? "shadow-none bg-purple-400"
            : "shadow-lg"
        } rounded-md p-2 lg:p-3 md:p-3 text-white mx-2 font-medium`}
        onClick={() => handleClick("allJanazas", handleAllJanazaClick)}
      >
        <span
          aria-label="toutes les janazas"
          className="text-sm lg:text-lg md:text-lg"
        >
          Toutes les Janazas
        </span>
      </button>
      <button
        className={`bg-blue-700 hover:bg-opacity-80 ${
          selectedButton === "todayJanazas"
            ? "shadow-none bg-purple-400"
            : "shadow-lg"
        } rounded-md p-2 lg:p-3 md:p-3 text-white mx-2 font-medium`}
        onClick={() => handleClick("todayJanazas", handleTodayJanazaClick)}
      >
        <span
          aria-label="janazas du jour"
          className="text-sm lg:text-lg md:text-lg"
        >
          Les Janazas d'Aujourd'hui
        </span>
      </button>
      <button
        className={`bg-blue-700 hover:bg-opacity-80 ${
          selectedButton === "nextDateJanazas"
            ? "shadow-none bg-purple-400"
            : "shadow-lg"
        } rounded-md p-2 lg:p-3 md:p-3 text-white mx-2 font-medium`}
        onClick={() => handleClick("nextDateJanazas", handleNextDateClick)}
      >
        <span
          aria-label="janaza demain"
          className="text-sm lg:text-lg md:text-lg"
        >
          Les Janazas Demain
        </span>
      </button>
  <ToolTip tooltipMessage={`${showMap ? msgToolTip2 : msgToolTip}`}>
        <button
          onClick={handleShowMap}
          className="bg-blue-700 hover:bg-opacity-80 rounded-md p-3 text-white mx-2 font-medium my-1"
        >
          <FontAwesomeIcon
            className="text-lg"
            aria-label="afficher la carte"
            icon={showMap ? faMap : faLocationDot}
          />
        </button>
          
        </ToolTip>
    </div>
  );
};
export default DisplayJanazaButton;
