import { useState, useEffect } from "react";
import {
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { Link, useLocation } from "react-router-dom";
import { db } from "../firebase";
import FormJanaza from "../components/FormJanaza";
import { PropsOnChange } from "../types/index.types";
import { UserAuth } from "../context/AuthContext";
import ModalDelete from "../components/ModalDelete";

import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { DateFormatUtils } from "../utils/dateFormatUtils";
import { TimeFormatUtils } from "../utils/timeFormatUtils";

import wazeLogo from "./../img/waze-logo.png";
import googleMapsLogo from "./../img/googlemaps-logo.png";
import { CapitalizeFirstLetter } from "../utils/capitalizeString";
import CircleColoredLetter from "../components/CircleColoredLetter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faLocationDot,
  faBed,
  faSquarePhone,
  faTrashCan,
  faPenToSquare,
  faLocationArrow,
  faAnglesLeft,
} from "@fortawesome/free-solid-svg-icons";
import GoogleMapMaps from "../components/GoogleMapMaps";
import Spinner from "../components/Spinner";

import AddressIcon from "../components/AddressIcon";

const SinglePost = () => {
  const [post, setPost] = useState<DocumentData | undefined>();
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const { user } = UserAuth();
  const [addressInput, setAddressInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "all-janaza", path);
        const docSnap = await getDoc(docRef);
        setPost(docSnap?.data());
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [path]);

  const handleChange = (e: PropsOnChange) => {
    const { name, value } = e.target;
    setPost({
      ...post,
      [name]: value,
      addressInput,
    });
  };

  const handleDelete = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await deleteDoc(doc(db, "all-janaza", path));
      window.location.replace("/");
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const updateRef = doc(db, "all-janaza", path);
      await updateDoc(updateRef, {
        ...post,
        addressInput,
      });
      window.location.replace("/post/" + path);
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowUpdateForm = (e: React.FormEvent) => {
    e.preventDefault();
    setShowUpdateForm(true);
  };
  const handleShowModal = (e: React.FormEvent) => {
    e.preventDefault();
    setShowModal(!showModal);
  };
  const google = `https://www.google.com/maps/place/${
    post?.addressInput?.label
  }/@${post?.addressInput?.lat},${post?.addressInput?.lon},${18}z`;

  const waze = `https://waze.com/ul?q=${post?.addressInput?.label}&ll=${post?.addressInput?.lat},${post?.addressInput?.lon}&navigate=yes`;

  const socialUrl = `https://janazafinder.com/post/${path}`;

  //date of creation
  const dateOfCreation = DateFormatUtils.dateToFrenchFormat(
    post?.timeStamp?.toDate().toString()
  );
  // format janaza date
  const formattedDateJanaza = DateFormatUtils.dateToFrenchFormat(
    post?.dateJanaza
  );

  //Format to french time
  const formattedTime = TimeFormatUtils.timeToFrenchFormat(post?.time);

  //Capitalize the first letter of username
  const firstLetterUserName: string | undefined =
    post?.username || user?.displayName // check if either post?.username or user?.displayName is defined
      ? (post?.username || user?.displayName)[0].toUpperCase()
      : undefined;

  //Username display either from gmail or phone auth
  const userNameDisplay: string | undefined =
    post?.username || user?.displayName // check if either post?.username or user?.displayName is defined
      ? CapitalizeFirstLetter.capitalizeString(
          post?.username ?? user?.displayName
        )
      : undefined;

  //Check if it is not empty or null
  const prayerTypeName = post?.prayerName || "";

  return isLoading ? (
    <Spinner
      heightScreen={"h-screen"}
      justify={"center"}
      color='blue-700'
      width={20}
      height={20}
    />
  ) : (
    <div className=''>
      <section className='w-full'>
        <div className=''>
          <GoogleMapMaps postAddressInput={post?.addressInput} />
        </div>
      </section>
      {/* <hr className="h-2 mb-5 bg-gradient-to-r from-purple-100 to-green-apple" /> */}
      <Link
        to='/'
        className='flex items-center w-28 bg-blue-500 hover:bg-blue-700 text-white p-1 lg:p-3 md:p-3'
      >
        <FontAwesomeIcon className='h-5 w-5' icon={faAnglesLeft} />
        <span className='text-lg le:text-lg md:text-lg mx-2'>Retour</span>
      </Link>

      {!showUpdateForm ? (
        <section className='mx-auto w-full lg:w-9/12 md:w-4/6  flex justify-center shadow-md p-5 rounded-md m-5'>
          <article className='w-full'>
            <header className='flex justify-between'>
              <div className='flex items-center'>
                <CircleColoredLetter
                  firstLetterUserName={firstLetterUserName}
                />

                <p className='text-gray-900 leading-none'>{userNameDisplay}</p>
              </div>
              <div className='flex justify-center'>
                {(user?.displayName === post?.username ||
                  user?.phoneNumber === post?.phoneNumber ||
                  user?.email === process.env.REACT_APP_ADMIN_EMAIL_2) && (
                  <>
                    <button
                      className=' text-red hover:opacity-75'
                      onClick={() => setShowModal(true)}
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className='h-4 w-4 lg:h-6 lg:w-6 md:h-6 md:w-6 mx-2'
                      />
                    </button>
                  </>
                )}
                {user?.email === process.env.REACT_APP_ADMIN_EMAIL_2 && (
                  <button
                    className='text-green-apple hover:opacity-75'
                    onClick={handleShowUpdateForm}
                  >
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      className='h-4 w-4 lg:h-6 lg:w-6 md:h-6 md:w-6 mx-2'
                    />
                  </button>
                )}
              </div>
              <p className='flex items-center text-xs text-purple-800'>
                <em>posté le {dateOfCreation}</em>
              </p>
            </header>
            <hr className='mx-auto text-gray  my-3' />
            <section className='flex-none lg:flex md:flex items-center justify-between'>
              <h1 className='text-lg lg:text-2xl md:text-2xl font-bold'>
                Salat Janaza le {formattedDateJanaza}
              </h1>

              <div>
                <h3 className='flex items-center'>
                  <FontAwesomeIcon
                    icon={faClock}
                    className='h-4 w-4 lg:h-5 lg:w-5 md:h-5 md:w-5 text-purple-700 mr-1'
                  />
                  <span className='text-lg lg:text-xl md:text-xl font-semibold'>
                    Horaire de prière
                  </span>
                </h3>
                <table className='flex justify-center table-fixed border border-gray'>
                  <tbody>
                    <tr>
                      <td className='font-semibold text-lg lg:text-lg md:text-lg'>
                        {post?.prayerPosition ? post?.prayerPosition : null}
                        {prayerTypeName ? ` ${prayerTypeName} ` : null}
                      </td>
                      <td className='text-lg lg:text-lg md:text-lg'>
                        {formattedTime ? ` ${formattedTime} ` : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <div className='flex justify-start items-center'>
              <AddressIcon types={post?.addressInput.types} />{" "}
              <p className='text-lg lg:text-xl md:text-xl'>
                {post?.addressInput.name}
              </p>
            </div>

            <div className='my-2'>
              <p className='flex-none lg:flex md:flex justify-start items-center'>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className='h-4 w-4 lg:h-5 lg:w-5 md:h-5 md:w-5 text-purple-700 mr-1'
                />{" "}
                <span className='text-lg lg:text-xl md:text-xl'>
                  {post?.addressInput?.label}
                </span>
              </p>
            </div>
            {post?.deceased && (
              <p className='flex justify-start items-center text-lg'>
                <FontAwesomeIcon
                  icon={faBed}
                  className='h-6 w-6 text-purple-700 mr-1'
                />
                <span className='text-lg lg:text-xl md:text-xl'>
                  {post?.deceased}
                </span>
              </p>
            )}
            {post?.phoneNumber.length >= 2 && (
              <p className='flex items-center my-2'>
                <FontAwesomeIcon
                  icon={faSquarePhone}
                  className='h-4 w-4 lg:h-5 lg:w-5 md:h-5 md:w-5 mr-1 text-purple-700'
                />
                <a
                  className='text-lg lg:text-xl md:text-xl'
                  href={`tel:${post?.phoneNumber}`}
                >
                  {post?.phoneNumber}
                </a>
              </p>
            )}
            {post?.detail && (
              <div className='my-2'>
                <label
                  className='text-lg lg:text-lg md:text-lg italic underline underline-offset-1 font-bold'
                  htmlFor='information'
                >
                  Information Complémentaire :
                </label>
                <p className='text-lg lg:text-lg md:text-lg h-auto bg-light-gray'>
                  {" "}
                  {post?.detail}
                </p>
              </div>
            )}
            <footer className='flex justify-between items-center mt-3'>
              <div>
                <p className='text-sm lg:text-lg md:text-lg mb-1'>
                  Partage cette Janaza avec ton réseau
                </p>
                <div className='flex justify-around'>
                  <FacebookShareButton
                    className='mr-2'
                    url={socialUrl}
                    quote={`Salat Janaza le ${formattedDateJanaza}, ${post?.addressInput.name}`}
                  >
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                  <TelegramShareButton
                    className='mx-1'
                    url={socialUrl}
                    title={`Salat Janaza le ${formattedDateJanaza}, ${post?.addressInput.name}`}
                  >
                    <TelegramIcon size={32} round={true} />
                  </TelegramShareButton>
                  <TwitterShareButton
                    className='mx-1'
                    url={socialUrl}
                    title={`Salat Janaza le ${formattedDateJanaza}, ${post?.addressInput.name}`}
                  >
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    className='mx-1'
                    url={socialUrl}
                    title={`Salat Janaza le ${formattedDateJanaza}, ${post?.addressInput.name}`}
                  >
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                </div>
              </div>
              <div className='flex justify-center items-center'>
                <a
                  className='mx-3'
                  target='_blank'
                  href={google}
                  rel='noreferrer'
                  aria-label='Google Maps logo'
                >
                  <img
                    src={googleMapsLogo}
                    alt='google-logo'
                    className='h-12 w-12'
                  />
                  <FontAwesomeIcon icon={faLocationArrow} />
                  <span className='text-sm lg:text-lg md:text-lg'>
                    Use Google
                  </span>
                </a>
                <a
                  className='mx-3'
                  rel='noreferrer'
                  target='_blank'
                  href={waze}
                  aria-label='Waze logo'
                >
                  <img src={wazeLogo} alt='waze-logo' className='h-12 w-12' />
                  <FontAwesomeIcon icon={faLocationArrow} />
                  <span className='text-sm lg:text-lg md:text-lg'>
                    Use Waze
                  </span>
                </a>
              </div>
            </footer>
          </article>

          {/* HANDLE DELETE */}
          {showModal ? (
            <ModalDelete
              handleDelete={handleDelete}
              handleShowModal={handleShowModal}
              question={"Vous voulez vraiment effacer ?"}
            />
          ) : null}
        </section>
      ) : (
        <div className='w-full min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
          <div className='w-full space-y-8'>
            <div className='w-full max-w-2xl mx-auto'>
              <FormJanaza
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                showUpdateForm={showUpdateForm}
                setShowUpdateForm={setShowUpdateForm}
                post={post}
                addressInput={addressInput}
                setAddressInput={setAddressInput}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SinglePost;
