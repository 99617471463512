import React, { useState } from "react";

import { useLoadScript, Autocomplete } from "@react-google-maps/api";

import { Libraries } from "../types/index.types";
import Spinner from "./Spinner";

type SearchInputProps = {
  setAddressInput: any;

  setIsAtTheMasjid: any;
};
type Location = {
  label: string;
  lon: number;
  lat: number;
  name?: string;
  types: string[] | undefined;
  phone?: string | undefined;
};

const SearchAddressInput: React.FC<SearchInputProps> = ({
  setAddressInput,
  setIsAtTheMasjid,
}) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
    libraries: Libraries.ALL,
  });

  if (loadError)
    return (
      <Spinner justify={"center"} color='blue-700' width={10} height={10} />
    );
  if (!isLoaded)
    return (
      <Spinner justify={"center"} color='blue-700' width={10} height={10} />
    );
  const handleLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const handlePlaceSelect = () => {
    const place = autocomplete?.getPlace();

    if (place?.geometry?.location) {
      const newAddress: Location = {
        label: place.formatted_address ?? "",
        lon: place.geometry.location.lng(),
        lat: place.geometry.location.lat(),
        name: place.name,
        types: place.types,
      };

      setIsAtTheMasjid(
        place?.types?.includes("mosque") ||
          place?.name?.includes("mosque") ||
          place?.types?.includes("place_of_worship")
      );

      setAddressInput(newAddress);
    }
  };

  return (
    <>
      <label className='block text-gray-700 text-sm font-bold mb-2'>
        Lieu de la janaza <span className='text-red-700'>*</span>
      </label>
      <Autocomplete onLoad={handleLoad} onPlaceChanged={handlePlaceSelect}>
        <input
          className='w-full lg:w-3/4 md:w-3/4 shadow rounded py-2 px-3 text-gray-700'
          type='text'
          // defaultValue={defaultInputValue}
          id='address'
          name='address'
          placeholder='ex: mosquée de Paris..'
          required
        />
      </Autocomplete>
    </>
  );
};

export default SearchAddressInput;
