import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div
      className="
    flex
    items-center
    justify-center
    w-full
    lg:h-auto md:h-auto
    bg-gradient-to-r
    from-purple-100 to-purple-100
    py-28
  "
    >
      <div className="px-10 lg:px-40 md:px-40 py-5 lg:py-20 md:py-20 bg-white rounded-md shadow-xl">
        <div className="flex flex-col items-center">
          <h1 className="font-bold text-purple-800 text-9xl">404</h1>

          <h6 className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
            <span className="text-green-400">Oops!</span> Page non trouvée
          </h6>

          <p className="mb-8 text-center text-gray-500 md:text-lg">
            La page que vous recherchez n'existe pas.
          </p>

          <Link
            to="/"
            className="group relative w-full flex justify-center p-3 text-xl font-medium rounded-md text-white bg-gradient-to-r from-purple-500 to-purple-800   hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Retournez a l'accueil
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
