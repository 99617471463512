import screenshotPWA from "./../img/screenshot-pwa.png";
import screenshotPWA2 from "./../img/screenshot-pwa-2.png";
import screenshotPWA3 from "./../img/screenshot-pwa-3.png";
import screenshotPWA4 from "./../img/screenshot-pwa-4.png";
import StepbyStepInstall from "../components/StepbyStepInstall";

const InstallApp = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className="text-2xl font-bold text-center mb-8 underline decoration-blue-500 mt-5">
        Comment installer l'application sur votre téléphone portable
      </h1>

      <section className="w-full flex flex-wrap">
        {/* Etape 1 */}
        <StepbyStepInstall
          title="Étape 1"
          description={
            <>
              Accédez à l'application sur votre navigateur mobile <br />
              Appuyez sur le bouton{" "}
              <span className="underline decoration-blue-500">
                Ajouter à l'écran d'accueil
              </span>{" "}
              dans le menu de votre navigateur.
            </>
          }
          photo={screenshotPWA}
        />
        {/* Etape 1 bis */}
        <StepbyStepInstall
          title=" Si vous n'avez pas cette suggestion"
          description={
            <>
              Dans le{" "}
              <span className="underline decoration-purple-400">
                menu de votre navigateur.
              </span>{" "}
              Sur Safari iOS, cela peut être trouvé dans le menu de partage.
            </>
          }
          photo={screenshotPWA2}
        />
        {/* Etape 2 */}
        <StepbyStepInstall
          title="Étape 2"
          description={
            <>
              Une boîte de dialogue vous demandera de confirmer l'installation
              de Janaza Finder. <br />
              Appuyez sur{" "}
              <span className="underline decoration-blue-500">
                Installer pour confirmer.
              </span>
            </>
          }
          photo={screenshotPWA3}
        />

        {/* Etape 3 */}
        <StepbyStepInstall
          title="Étape 3"
          description={
            <>
              <span className="underline decoration-purple-400">
                Janaza Finder
              </span>{" "}
              est maintenant installée sur votre téléphone portable et vous
              pouvez la trouver sur votre écran d'accueil.
            </>
          }
          photo={screenshotPWA4}
        />
      </section>

      <p className="text-center font-bold text-xl lg:text-2xl md:text-2xl  w-full lg:w-3/4 md:3/4 my-3">
        🎉✨Et voilà! Vous avez maintenant installé JanazaFinder sur votre
        téléphone portable✨🎉
      </p>
    </div>
  );
};

export default InstallApp;
