import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PropsDataPost } from "../types/index.types";
import { DateFormatUtils } from "../utils/dateFormatUtils";
import { TimeFormatUtils } from "../utils/timeFormatUtils";
import { UserAuth } from "../context/AuthContext";
import { CapitalizeFirstLetter } from "../utils/capitalizeString";
import CircleColoredLetter from "./CircleColoredLetter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import AddressIcon from "./AddressIcon";

const Post = ({ data }: PropsDataPost) => {
  const { user } = UserAuth();
  const [janazaBg, setJanazaBg] = useState("");
  const {
    addressInput,
    dateJanaza,
    time,
    id,
    timeStamp,
    username,
    prayerPosition,
    prayerName,
    deceased,
  } = data;

  //janaza date
  const formattedDateJanaza = DateFormatUtils.dateToFrenchFormat(dateJanaza);

  //date of creation
  const dateOfCreation = DateFormatUtils.dateToFrenchFormat(
    timeStamp?.toDate().toString()
  );

  // time to french format
  const formattedTime = TimeFormatUtils.timeToFrenchFormat(time);

  useEffect(() => {
    //date of today
    const now = new Date();

    //date of janaza
    const janazaDate = new Date(dateJanaza);

    //Check if the date of janaza is passed make bg gray
    if (janazaDate.getDate() === now.getDate()) {
      setJanazaBg("bg-white");
    } else if (janazaDate < now) {
      setJanazaBg("bg-gray blur-[1.5px]");
    } else {
      setJanazaBg("bg-white");
    }
  }, [dateJanaza]);

  //check the length of the address for display purpose
  const addressLabel =
    addressInput?.label.length > 57
      ? addressInput?.label.substring(0, 57) + "..."
      : addressInput?.label;

  //Capitalize the first letter of username
  const firstLetterUserName: string | undefined =
    CapitalizeFirstLetter.capitalizeString(
      deceased || username || user?.displayName
    )[0];

  // Username display either from Gmail or phone auth
  const userNameDisplay: string | undefined =
    CapitalizeFirstLetter.capitalizeString(
      deceased || username || user?.displayName || ""
    );

  return (
    <article
      className={`p-1 overflow-hidden rounded-lg shadow-lg cursor-pointer  ${
        janazaBg.includes("bg-gray")
          ? "bg-gray"
          : "bg-gradient-to-r from-purple-100 to-purple-300"
      }`}
    >
      <div className={`p-4 rounded-lg ${janazaBg}`}>
        <Link to={`/post/${id}`}>
          <header className="flex-none lg:flex md:flex-none items-center justify-between">
            <h1
              className="text-lg lg:text-xl md:text-xl font-semibold"
              aria-label="Title card"
            >
              Salat Janaza {formattedDateJanaza}
            </h1>

            <p
              className="w-1/2 flex justify-start lg:justify-end md:justify-start items-center text-lg leading-normal font-semibold"
              aria-label="janaza time"
            >
              {prayerPosition && prayerName ? (
                <>
                  {" "}
                  <FontAwesomeIcon
                    icon={faClock}
                    className="h-4 w-4 lg:h-5 lg:w-5 md:h-5 md:w-5  text-blue-700 mr-1"
                  />
                  {prayerPosition} {prayerName}
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={faClock}
                    className="h-4 w-4 lg:h-5 lg:w-5 md:h-5 md:w-5  text-blue-700 mr-1"
                  />
                  {formattedTime}
                </>
              )}
            </p>
          </header>
          <AddressIcon types={addressInput?.types} /> {addressInput?.name}
          <p className="flex-none lg:flex md:flex justify-start items-center text-sm leading-normal my-1">
            <span>
              <FontAwesomeIcon
                icon={faLocationDot}
                className="h-4 w-4 lg:h-5 lg:w-5 md:h-5 md:w-5  text-purple-700 mr-1"
              />
            </span>
            <span className="w-full">{addressLabel}</span>
          </p>
          <footer className="flex justify-between my-3">
            <div className="flex items-center">
              <CircleColoredLetter firstLetterUserName={firstLetterUserName} />

              <p
                className="text-gray-900 leading-normal"
                aria-label="user display name"
              >
                {userNameDisplay}
              </p>
            </div>
            <p className="flex items-center text-xs leading-normal">
              <em aria-label="date of creation">posté le {dateOfCreation}</em>{" "}
            </p>
          </footer>
        </Link>
      </div>
    </article>
  );
};
export default Post;
