import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { PropsOnChange } from "../types/index.types";
import FormJanaza from "./FormJanaza";
import { UserAuth } from "../context/AuthContext";
import addNotification from "react-push-notification";

type FormData = {
  username?: string;
  phoneNumber?: string;
};
const AddJanaza = () => {
  const [data, setData] = useState<FormData>({});
  const [addressInput, setAddressInput] = useState<any>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { user } = UserAuth();

  const handleChange = (e: PropsOnChange) => {
    const { name, value } = e.target;
    setData({
      ...data,
      username: user?.displayName || data?.username,
      phoneNumber: user?.phoneNumber || data?.phoneNumber,
      [name]: value,
    });
  };
  useEffect(() => {
    // remove error msg when addressInput is not empty
    if (addressInput.length !== 0) {
      setError("");
    }
  }, [addressInput, error]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Add a check for empty addressInput
    if (typeof addressInput === "string" && addressInput.trim() === "") {
      setError("Veuillez saisir une adresse svp.");
      return;
    }
    try {
      const docRef = await addDoc(collection(db, "all-janaza"), {
        ...data,
        timeStamp: serverTimestamp(),
        addressInput,
      });
      setSuccess(true);
      setTimeout(() => {
        window.location.replace("/post/" + docRef.id);
        addNotification({
          title: "Une Janaza a été ajoutée",
          message: `Lieu de la janaza: ${addressInput?.name || ""}`,
          theme: "light",
          vibrate: 1,
          native: true, // when using native, your OS will handle theming.
          icon: "./../img/janazafinder-removebg.png",
        });
      }, 1000);
    } catch (error) {
      console.log(error);
      setSuccess(false);
    }
  };

  return (
    <div className='w-full min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
      <div className='w-full space-y-8 '>
        <div className='w-full max-w-2xl mx-auto'>
          <FormJanaza
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            setAddressInput={setAddressInput}
            addressInput={addressInput}
            success={success}
            error={error}
          />
        </div>
      </div>
    </div>
  );
};

export default AddJanaza;
