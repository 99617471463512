import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../components/AlertMessage";
import { UserAuth } from "../context/AuthContext";

const PhoneLogin = () => {
  const [number, setNumber] = useState<string | undefined>();
  const [error, setError] = useState("");
  const [otp, setOtp] = useState("");
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [confirmObj, setConfirmObj] = useState<any>("");

  const { setUpRecaptcha } = UserAuth();
  const navigate = useNavigate();

  const getOtp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    if (number === "" || number === undefined) {
      return setError("Please enter a valid phone number");
    }

    try {
      const response = await setUpRecaptcha(number);
      setConfirmObj(response);
      setShowVerifyOtp(true);
    } catch (err: any) {
      setError(err.message);
      console.log(err);
    }
  };

  const verifyOtp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (otp === "" || otp === null) return;
    try {
      setError("");
      await confirmObj.confirm(otp);
      navigate("/add");
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleCancel = (e: React.FormEvent) => {
    e.preventDefault();
    navigate("/login");
  };

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="w-full max-w-xs mx-auto">
          <form
            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            onSubmit={getOtp}
            style={{ display: !showVerifyOtp ? "block" : "none" }}
          >
            {error && (
              <AlertMessage message={error} color="red" text="red" bg="white" />
            )}
            <PhoneInput
              defaultCountry="FR"
              value={number}
              onChange={setNumber}
              placeholder="Enter Phone number"
              className="border border-purple-500 p-2 rounded-md"
            />
            <div id="recaptcha-container" />
            <div className="flex justify-center mt-4">
              <button className="bg-blue-700 p-2 lg:p-3 md:p-3 mx-2 rounded-md text-white font-medium shadow-lg">
                Envoyez le OTP
              </button>
              <button
                onClick={handleCancel}
                className="bg-purple-400 p-2 lg:p-3 md:p-3 mx-2 rounded-md text-white font-medium shadow-lg"
              >
                Cancel
              </button>
            </div>
          </form>

          <form
            className=" bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            onSubmit={verifyOtp}
            style={{ display: showVerifyOtp ? "block" : "none" }}
          >
            {error && (
              <AlertMessage message={error} color="red" text="red" bg="white" />
            )}
            <input
              type="text"
              placeholder="Entrez le OTP Reçu"
              onChange={(e) => setOtp(e.target.value)}
              className="w-full border border-purple-500 p-2 rounded-md"
            />
            <div className="flex justify-center mt-4">
              <button className="bg-blue-700 p-2 lg:p-3 md:p-3 mx-2 rounded-md text-white font-medium shadow-lg">
                Vérifie le OTP
              </button>
              <button className="bg-purple-400 p-2 lg:p-3 md:p-3 mx-2 rounded-md text-white font-medium shadow-lg">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default PhoneLogin;
