import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";

// import GreetingMessage from "../components/GreetingMessage";
import { logEvent } from "firebase/analytics";
import Posts from "../components/Posts";
import SearchCity from "../components/SearchCity";
import { analytics, db } from "../firebase";

import AddressNotFoundErrMsg from "../components/AddressNotFoundErrMsg";
import AllJanazaMap from "../components/AllJanazaMap";
import DisplayJanazaButton from "../components/DisplayJanazaButton";
import GreetingMessage from "../components/GreetingMessage";
import ScrollToTop from "../components/ScrollToTheTop";
import Spinner from "../components/Spinner";

export type AddressInput = {
  [x: string]: any;
  types?: string[];
  lat?: number;
  label?: string;
  name?: string;
  lon?: number;
};

export type AdressJ = {
  addressInput?: AddressInput;
};
const Home = () => {
  const [allData, setAllData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showTodayJanaza, setShowTodayJanaza] = useState(false);
  const [nextDate, setNextDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const POSTS_PER_PAGE = 12; // Number of posts to show
  const POSTS_ADDED_PER_CLICK = 6; // Number of posts to add when the user clicks the "Load More" button
  const [numPostsToShow, setNumPostsToShow] = useState(POSTS_PER_PAGE);
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    // Get the current date in ISO format
    const today = new Date().toISOString().slice(0, 10);
    //Collection docRef
    const colRef = collection(db, "all-janaza");
    //Query
    const q = query(
      colRef,
      orderBy("dateJanaza", "asc"),
      where("dateJanaza", ">=", today)
    );

    // Listen to realtime update
    const unsub = onSnapshot(
      q,
      (snapShot) => {
        let lists: any = [];
        snapShot.docs.forEach((doc) => {
          lists.push({ id: doc.id, ...doc.data() });
        });
        setAllData(lists);
        setIsLoading(false); // Set isLoading to false once the data has been fetched
      },
      (err) => {
        console.log(err);
        setIsLoading(false); // Also set isLoading to false if there's an error
      }
    );
    return () => {
      unsub();
    };
  }, []);

  // Get the current date in ISO format
  const today = new Date().toISOString().slice(0, 10);
  // get next day's date in format of "dateJanaza" field
  const nextDay = new Date(Date.now() + 86400000).toISOString().slice(0, 10);

  // match addresses with or without accents in the filter search.
  const stripAccents = (text: string) => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filteredPost = allData?.filter(
    (val: any) =>
      stripAccents(val?.addressInput?.label.toLowerCase()).includes(
        stripAccents(searchTerm.toLowerCase())
      ) &&
      (!showTodayJanaza || val?.dateJanaza === today) && // only show today's posts if the button is clicked
      (!nextDate || val?.dateJanaza === nextDate) // only show posts for the next date if the button is clicked
  );
  const handleAllJanazaClick = () => {
    setShowTodayJanaza(false);
    setNextDate(""); // reset the nextDate state to an empty string
  };
  const handleTodayJanazaClick = () => {
    setShowTodayJanaza(true);
    setNextDate(""); // reset the nextDate state to an empty string
  };
  const handleNextDateClick = () => {
    setNextDate(nextDay);
    setShowTodayJanaza(false);
  };
  const handleLoadMoreClick = () => {
    setNumPostsToShow(numPostsToShow + POSTS_ADDED_PER_CLICK);
  };

  logEvent(analytics, "select_content", {
    content_type: "image",
    content_id: "P12453",
    items: [{ name: "Kittens" }],
  });

  const handleShowMap = () => {
    setShowMap(!showMap);
  };

  const addressJanaza: any = allData?.map((val: AdressJ) => val?.addressInput);

  return (
    <div>
      <GreetingMessage />

      <SearchCity searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <DisplayJanazaButton
        handleAllJanazaClick={handleAllJanazaClick}
        handleTodayJanazaClick={handleTodayJanazaClick}
        handleNextDateClick={handleNextDateClick}
        handleShowMap={handleShowMap}
        showMap={showMap}
      />

      {showMap ? (
        addressJanaza && <AllJanazaMap addressInput={addressJanaza} />
      ) : (
        <>
          <div>
            {isLoading ? (
              <Spinner
                heightScreen={"h-60"}
                justify={"center"}
                color='blue-700'
                width={16}
                height={16}
              />
            ) : filteredPost.length > 0 ? (
              <Posts allData={filteredPost.slice(0, numPostsToShow)} />
            ) : (
              <AddressNotFoundErrMsg />
            )}
          </div>

          {numPostsToShow < filteredPost.length && (
            <div className='flex justify-center'>
              <button
                className='bg-purple-400 rounded-md p-2 lg:p-3 md:p-3 text-white mx-2 font-medium'
                onClick={handleLoadMoreClick}
              >
                Voir plus
              </button>
            </div>
          )}
        </>
      )}
      <ScrollToTop />
    </div>
  );
};
export default Home;
