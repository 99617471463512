import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { analytics } from "./firebase";
import { logEvent } from "firebase/analytics";

import "./App.css";
import AddJanaza from "./components/AddJanaza";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import PhoneLogin from "./components/PhoneLogin";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthContextProvider } from "./context/AuthContext";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import SinglePost from "./pages/SinglePost";
import Admin from "./pages/Admin";
import JanazaReward from "./pages/JanazaReward";
import ProtectedRouteAdmin from "./components/ProtectedRouteAdmin";
import InstallApp from "./pages/InstallApp";

function App() {
  const location = useLocation();

  useEffect(() => {
    // Track user behavior in Firebase Analytics

    logEvent(analytics, "page_view", { page_path: location.pathname });
  }, [location]);

  return (
    <>
      <AuthContextProvider>
        <Navbar />
        {/* <div
          className={`bg-white border  border-red text-red px-4 py-3 rounded relative mb-2 text-center`}
          role="alert"
        >
          <span className="sm:inline block">
            message to add here
          </span>
        </div> */}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/post/:postId" element={<SinglePost />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/phonelogin" element={<PhoneLogin />} />
          <Route path="/janaza-info" element={<JanazaReward />} />
          <Route path="/install-app" element={<InstallApp />} />
          <Route
            path="/add"
            element={
              <ProtectedRoute>
                <AddJanaza />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRouteAdmin>
                <Admin />
              </ProtectedRouteAdmin>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </AuthContextProvider>
    </>
  );
}

export default App;
