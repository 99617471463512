type CircleColoredLetterProps = {
  firstLetterUserName: string | undefined;
};
const CircleColoredLetter = ({
  firstLetterUserName,
}: CircleColoredLetterProps) => {
  return (
    <div
      className={`w-6 h-6 rounded-full mr-2 bg-gradient-to-r from-purple-700 to-purple-800 flex items-center justify-center`}
    >
      <span className="text-white font-bold">{firstLetterUserName}</span>
    </div>
  );
};
export default CircleColoredLetter;
