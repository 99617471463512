import { useEffect, useState } from "react";
import {
  DocumentData,
  collection,
  orderBy,
  query,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";

import TableAdmin from "../components/TableAdmin";

const Admin = () => {
  const [AllPosts, setAllPosts] = useState<DocumentData | undefined>();

  useEffect(() => {
    //Collection docRef
    const colRef = collection(db, "all-janaza");
    //Query
    const q = query(colRef, orderBy("dateJanaza", "asc"));

    // Listen to realtime update
    const unsub = onSnapshot(
      q,
      (snapShot) => {
        let lists: any = [];
        snapShot.docs.forEach((doc) => {
          lists.push({ id: doc.id, ...doc.data() });
        });
        setAllPosts(lists);
      },
      (err) => {
        console.log(err);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  return (
    <div>
      <h1 className="flex justify-center text-5xl font-medium">Admin Panel</h1>
      {AllPosts?.map((post: any, i: any) => {
        return (
          <div key={i}>
            <TableAdmin post={post} />
          </div>
        );
      })}
    </div>
  );
};
export default Admin;
