import { PropsDataPosts } from "../types/index.types";
import Post from "./Post";

const Posts = ({ allData }: PropsDataPosts) => {
  return (
    <div className='flex justify-between flex-wrap lg:my-4 lg:px-1 lg:w-full md:w-2/2'>
      {allData.map((data, i) => {
        return (
          <div
            className='my-3 px-1 w-full lg:my-4 lg:px-2 lg:max-w-xl md:max-w-xl'
            key={i}
          >
            <Post data={data} />
          </div>
        );
      })}
    </div>
  );
};

export default Posts;
