import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { UserAuth } from "../context/AuthContext";

import { Disclosure } from "@headlessui/react";

import Logo from "./../img/janazafinder-removebg.png";
import ModalDelete from "./ModalDelete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faBars,
  faHouse,
  faMagnifyingGlass,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [showModal, setShowModal] = useState(false);
  const { user, logOut } = UserAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await logOut();
      navigate("/");
      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleShowModal = (e: React.FormEvent) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  return (
    <div className="w-full">
      <Disclosure as="nav" className="shadow-md">
        {({ open, close }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center md:hidden sm:hidden ">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="block h-6 w-6 text-purple-900"
                        aria-hidden="true"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faBars}
                        className="block h-6 w-6 text-purple-900"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
                <Link
                  className="flex-shrink-0 flex items-center 
  												px-3"
                  to="/"
                ></Link>
                <div className="flex-1 flex items-center justify-center md:items-stretch sm:items-stretch md:justify-start sm:justify-start">
                  <Link
                    className="flex-shrink-0 flex items-center 
  												px-3"
                    to="/"
                  >
                    <img
                      className="hidden lg:block h-20"
                      src={Logo}
                      alt="logo"
                    />
                  </Link>
                  <div className="hidden md:flex md:items-center sm:block">
                    <div className="flex items-center space-x-4">
                      <Link
                        className="hover:bg-purple-900 hover:text-white border border-purple-900 
  												px-3 py-2 rounded-md text-xl font-medium"
                        to="/"
                      >
                        Accueil
                      </Link>

                      <Link
                        className="hover:bg-purple-900 hover:text-white border border-purple-900 
  												px-3 py-2 rounded-md text-xl font-medium"
                        to="/contact"
                      >
                        Contactez-nous
                      </Link>
                      <Link
                        className="hover:bg-purple-900 hover:text-white border border-purple-900 
  												px-3 py-2 rounded-md text-xl font-medium"
                        to="/janaza-info"
                      >
                        Récompense
                      </Link>
                      <Link
                        className="hover:bg-purple-900 hover:text-white border border-purple-900 
  												px-3 py-2 rounded-md text-xl font-medium"
                        to="/install-app"
                      >
                        Installer l'application
                      </Link>
                      {user &&
                      user?.email === process.env.REACT_APP_ADMIN_EMAIL_2 ? (
                        <Link
                          className="hover:bg-purple-900 hover:text-white border border-purple-900 
  												px-3 py-2 rounded-md text-xl font-medium"
                          to="/admin"
                        >
                          Admin
                        </Link>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2  md:static md:inset-auto md:ml-6 md:pr-0   sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* Add janaza button */}
                  {user ? (
                    <>
                      <Link
                        to="/add"
                        className="bg-gray-300 hover:bg-purple-900 hover:text-white text-gray-800 border border-purple-900 font-bold py-2 px-4 rounded inline-flex items-center mr-4"
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="w-5 h-5 mr-2"
                        />

                        <span>Créer une janaza</span>
                      </Link>

                      <button
                        onClick={handleShowModal}
                        aria-label="se déconnecter"
                        aria-pressed={showModal}
                        title="se déconnecter"
                      >
                        <FontAwesomeIcon
                          icon={faArrowRightFromBracket}
                          className="h-8 w-8 text-red"
                        />
                      </button>
                      {/* HANDLE SIGNOUT */}
                      {showModal ? (
                        <ModalDelete
                          handleDelete={handleSignOut}
                          handleShowModal={handleShowModal}
                          question={
                            "Vous êtes sur le point de vous déconnecter. Êtes-vous sûr(e) de vouloir le faire ?"
                          }
                        />
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Link
                        to="/login"
                        className="bg-purple-900 text-white hover:bg-white hover:text-purple-900 border border-purple-900 font-bold py-2 px-4 rounded inline-flex items-center mr-4"
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="w-5 h-5 mr-2"
                        />
                        <span>Login | Créer une alerte janaza</span>
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                <Disclosure.Button>
                  <Link
                    className="bg-purple-900 text-white
  										 hover:bg-gray-700 hover:text-white
  									block px-3 py-2 my-1 rounded-md text-base font-medium"
                    to="/"
                    onClick={() => close()}
                  >
                    Accueil
                  </Link>

                  <Link
                    className="bg-purple-900 text-white
  										 hover:bg-gray-700 hover:text-white
  									block px-3 py-2 my-1 rounded-md text-base font-medium"
                    to="/contact"
                    onClick={() => close()}
                  >
                    Contactez-nous
                  </Link>
                  <Link
                    className="bg-purple-900 text-white
  										 hover:bg-gray-700 hover:text-white
  									block px-3 py-2 my-1 rounded-md text-base font-medium"
                    to="/janaza-info"
                    onClick={() => close()}
                  >
                    Récompense
                  </Link>
                  <Link
                    className="bg-purple-900 text-white
  										 hover:bg-gray-700 hover:text-white
  									block px-3 py-2 my-1 rounded-md text-base font-medium"
                    to="/install-app"
                    onClick={() => close()}
                  >
                    Installer l'application
                  </Link>

                  {user &&
                  user?.email === process.env.REACT_APP_ADMIN_EMAIL_2 ? (
                    <Link
                      className="bg-purple-900 text-white
  										 hover:bg-gray-700 hover:text-white
  									block px-3 py-2 my-1 rounded-md text-base font-medium"
                      to="/admin"
                      onClick={() => close()}
                    >
                      Admin
                    </Link>
                  ) : null}
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="z-10 bottomNav fixed bottom-0 w-full">
        <nav
          style={{ border: "1px blue" }}
          className="md:hidden bottom-0 w-full bg-purple-400"
        >
          <ul className="flex justify-around items-center text-white text-center">
            <li className="flex p-4">
              <Link to="/">
                <span className="block font-medium">Accueil</span>
                <FontAwesomeIcon className="h-6 w-6 mx-auto" icon={faHouse} />
              </Link>
            </li>
            <li className="p-4 ">
              <a href="/#search">
                <span className="block font-medium">Search</span>
                <FontAwesomeIcon
                  className="h-6 w-6 mx-auto"
                  icon={faMagnifyingGlass}
                />
              </a>
            </li>
            {user ? (
              <>
                <li className="p-4  ">
                  <Link to="/add">
                    <span className="block font-medium">Add</span>
                    <FontAwesomeIcon
                      className="h-6 w-6 mx-auto"
                      icon={faPlus}
                    />
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="p-4 ">
                  <Link to="/login">
                    <span className="block font-medium">Login</span>
                    <FontAwesomeIcon
                      icon={faArrowRightFromBracket}
                      className="h-6 w-6 mx-auto"
                    />
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
