export type PropsOnChange = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
>;
export type PropsDataPost = {
  data: any;
};
export type PropsDataPosts = {
  allData: string[];
};

export type ModalDeleteProps = {
  handleDelete: React.MouseEventHandler<HTMLButtonElement>;
  handleShowModal: React.MouseEventHandler<HTMLButtonElement>;
  question: string;
};
export type DateOptionProps = {
  weekday?: "long" | "short" | "narrow";
  year: "numeric" | "2-digit" | undefined;
  month: "long" | "short" | "narrow" | "2-digit";
  day: "numeric" | "2-digit" | undefined;
};

export type AddressSearch = {
  label: string;
  lon: number | undefined;
  lat: number | undefined;
};
export type AddressMaps = {
  label: string;
  lon: number;
  lat: number;
};

export type Library =
  | "geometry"
  | "places"
  | "drawing"
  | "localContext"
  | "visualization";

export const Libraries = {
  GEOMETRY: "geometry",
  PLACES: "places",
  DRAWING: "drawing",
  LOCAL_CONTEXT: "localContext",
  VISUALIZATION: "visualization",
  ALL: [
    "geometry",
    "places",
    "drawing",
    "localContext",
    "visualization",
  ] as Library[],
};
