import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type PropsSpinner = {
  heightScreen?: string | number;
  width: number;
  height: number;
  justify: string;
  color: string;
};
//blue-700
const Spinner = ({
  heightScreen,
  width,
  height,
  justify,
  color,
}: PropsSpinner) => {
  return (
    <div className={`flex items-center justify-${justify} ${heightScreen}`}>
      <FontAwesomeIcon
        icon={faSpinner}
        className={`text-${color} animate-spin  w-${width} h-${height}`}
      />
    </div>
  );
};

export default Spinner;
