import { useContext, createContext, useEffect, useState } from "react";
import {
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  signOut,
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "../firebase";

const INITIAL_STATE = JSON.parse(localStorage.getItem("user"));

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(INITIAL_STATE);

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  const microsoftSignIn = () => {
    // const provider = new OAuthProvider("microsoft.com");
    let provider = new OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      prompt: "consent",
      tenant: "1a87dc06-184e-41c4-8c03-4ddb20aaffbf",
    });
    signInWithPopup(auth, provider);
  };

  function setUpRecaptcha(number) {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  }

  const logOut = () => {
    signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    //we cannot store object inside localstorage so we convert into json
    localStorage.setItem("user", JSON.stringify(user));
    return () => {
      unsubscribe();
    };
  }, [user]);

  return (
    <AuthContext.Provider
      value={{ googleSignIn, microsoftSignIn, logOut, user, setUpRecaptcha }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
