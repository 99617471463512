import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

import { useEffect, useState } from "react";

import Spinner from "./Spinner";
import { AddressInput } from "../pages/Home";

type markerLocationProps = {
    types?: string[];
    lat?: number;
    label?: string;
    name?: string;
    lon?: number;
};

const AllJanazaMap = ({ addressInput }: { addressInput: AddressInput }) => {
  const [markerLocations, setMarkerLocations] = useState<AddressInput>();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
  });



  useEffect(() => {
    // Update marker locations when addressInput changes
      setMarkerLocations(addressInput);
  }, [addressInput]);

  return !isLoaded ? (
    <Spinner
      heightScreen={"h-60"}
      justify={"center"}
      color="blue-700"
      width={16}
      height={16}
    />
  ) : (
    <GoogleMap
      zoom={6}
      center={{ lat: 46.603354, lng: 1.888334}}
      mapContainerClassName="h-[30rem] lg:h-[30rem] md:h-[30rem] w-full mt-5"
    >
      {markerLocations?.map((location: markerLocationProps, index: number) => (
        <Marker
          key={index}
          position={{ lat: location?.lat ?? 0, lng: location?.lon ?? 0}}
        />
      ))}
    </GoogleMap>
  );
};
export default AllJanazaMap;
