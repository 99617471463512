import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

import { useMemo } from "react";

import Spinner from "./Spinner";

type googleMapProps = {
  postAddressInput: {
    lat: number;
    lon: number;
  };
};

const GoogleMapMaps = ({ postAddressInput }: googleMapProps) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
  });

  const lat = postAddressInput?.lat;
  const lng = postAddressInput?.lon;

  const center = useMemo(() => {
    if (typeof lat === "number" && typeof lng === "number") {
      return { lat, lng };
    }
    return { lat: 0, lng: 0 };
  }, [lat, lng]);

  return !isLoaded ? (
    <Spinner
      heightScreen={"h-60"}
      justify={"center"}
      color="blue-700"
      width={16}
      height={16}
    />
  ) : (
    <GoogleMap
      zoom={15}
      center={center}
      mapContainerClassName="h-60 lg:h-96 md:h-96"
    >
      {lat && lng && <Marker position={center} />}
    </GoogleMap>
  );
};
export default GoogleMapMaps;
