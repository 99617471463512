import notFoundData from "./../img/not-found.png";
const AddressNotFoundErrMsg = () => {
  return (
    <div className='mx-auto mt-5'>
      <p className='flex justify-center text-2xl font-medium mb-3'>
        Il n'y a pas de Janaza à ce jour !
      </p>
      <img
        src={notFoundData}
        alt='not found janaza'
        className='h-60 object-cover mx-auto'
      />
    </div>
  );
};
export default AddressNotFoundErrMsg;
