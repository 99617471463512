export type AlertMessageProps = {
  message: string;
  color?: string;
  text?: string;
  bg?: string;
};
const AlertMessage = ({ message, color, text, bg }: AlertMessageProps) => {
  return (
    <div
      className={`w-full lg:w-3/4 md:w-3/4 bg-${bg} border  border-${color} text-${text} p-2 rounded relative my-2 text-center`}
      role="alert"
    >
      <span className="sm:inline block">{message}</span>
    </div>
  );
};
export default AlertMessage;
