import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
type ProtectedRouteAdminProps = {
  children: JSX.Element;
};
const ProtectedRouteAdmin = ({ children }: ProtectedRouteAdminProps) => {
  const { user } = UserAuth();
  if (!user || user.email !== process.env.REACT_APP_ADMIN_EMAIL_2) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRouteAdmin;
