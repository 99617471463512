import React from "react";
import { ModalDeleteProps } from "../types/index.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

const ModalDelete = ({
  handleDelete,
  handleShowModal,
  question,
}: ModalDeleteProps) => {
  return (
    <>
      {/* Delete Product Modal  */}
      <div className="justify-center backdrop-blur-sm bg-purple-100 bg-opacity-50 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-6 lg:mx-auto md:mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col  w-full bg-purple-100 outline-none focus:outline-none p-2 lg:p-10 md:p-8">
            {/*body*/}
            <div className="p-6 pt-0 text-center">
              <FontAwesomeIcon
                className="mx-auto mb-4 w-14 h-14 text-red "
                icon={faCircleExclamation}
              />
              <h3 className="mb-5 text-lg font-medium text-gray-500 dark:text-gray-400">
                {question}
              </h3>
              <button
                data-modal-toggle="popup-modal"
                type="button"
                className="text-white bg-red hover:opacity-75 font-medium rounded-lg px-5 py-2.5 mr-2"
                onClick={handleDelete}
              >
                Oui, je suis sûre
              </button>
              <button
                data-modal-toggle="popup-modal"
                type="button"
                className="bg-white font-medium hover:bg-purple-300 rounded-lg px-5 py-2.5 "
                onClick={handleShowModal}
              >
                Non, annuler
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default ModalDelete;
